import { FC } from 'react';
import { EVENT_STATUS, EventLanding, LEADERBOARD_TYPES } from '@frx/frx-program-defaults';
// import { useGetEventFeatures, useLowercaseQueryString } from '@frx/frx-react-context';

export const EventLandingPage: FC = () => {
  // const {
  //   // eslint-disable-next-line @typescript-eslint/naming-convention
  //   queryStrings: { fr_id },
  // } = useLowercaseQueryString();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  // const { data: eventFeatures } = useGetEventFeatures(String(fr_id));
  // const featureFlags = eventFeatures?.featureFlag;
  // const leaderBoards = {
  //   [LEADERBOARD_TYPES.INDIVIDUALS]: featureFlags?.showIndividualsLeaderboards,
  //   [LEADERBOARD_TYPES.TEAMS]: featureFlags?.showTeamsLeaderboards,
  //   [LEADERBOARD_TYPES.COMMUNITIES]: featureFlags?.showCommunitiesLeaderboards,
  //   [LEADERBOARD_TYPES.EVENT_DONORS]: featureFlags?.showEventDonorsLeaderboards,
  // };

  // const keysWithTrueValues = Object.keys(leaderBoards)
  //   .filter((key) => (leaderBoards as never)[key] === true)
  //   .map(Number);

  const landingConfig = {
    ALLOW_EVENT_SPECIFIC_DONATIONS: true,
    CURRENT_EVENT_STATUS: EVENT_STATUS.ACCEPTING_REGISTRATIONS_AND_GIFTS, // see enum in defaults hook
    SHOW_LEADERBOARDS: [
      LEADERBOARD_TYPES.INDIVIDUALS,
      LEADERBOARD_TYPES.TEAMS,
      LEADERBOARD_TYPES.COMMUNITIES,
      LEADERBOARD_TYPES.EVENT_DONORS,
    ], // keysWithTrueValues,
    SHOW_PROGRESS_METER: true, // Boolean(featureFlags?.showProgressMeter) || true,
    SHOW_SIDEBAR: false,
    SHOW_SPONSORS: true,
  };

  return <EventLanding config={landingConfig} />;
};
