import './quill.module.scss';
import './index.scss';
import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { Global } from '@emotion/react';
import { ChakraProvider } from '@chakra-ui/react';
import { constants } from '@sj/sj-theme';
import { AuthDataProvider, EventDataProvider } from '@frx/frx-react-context';
import { Loader, ThemeProvider } from '@frx/frx-program-defaults';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { programTheme } from './theme';
import { ErrorFallBack } from './ErrorFallBack';
import { getSentryConfig } from './environment';

// // #region sentry configuration
const sentryConfig = getSentryConfig(window.location);
Sentry.init({
  dsn: 'https://0944ff4bd715214c0ff2eb4c325908be@o1429225.ingest.sentry.io/4506700746653696',
  attachStacktrace: true,
  enabled: sentryConfig.enabled,
  environment: sentryConfig.environment,
  // These domains are external for our app
  denyUrls: [
    /https:\/\/assets\.adobedtm\.com/,
    /https:\/\/cdn\.cookielaw\.org/,
    /https:\/\/maps\.googleapis\.com/,
    /https:\/\/s.go-mpulse\.net/,
  ],
  // This error is from Instagram browser and made sure no user impacted so far
  ignoreErrors: [
    /Can't find variable: _AutofillCallbackHandler/i,
    /Can't find variable: jQuery/i,
    /jQuery is not defined/i,
  ],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      maskAllInputs: true,
      blockAllMedia: true,
    }),
    // TODO: move this to it's own file and use chakra colors
    Sentry.feedbackIntegration({
      showBranding: false,
      buttonLabel: 'Feedback?',
      formTitle: 'Tell us what you think',
      namePlaceholder: 'Full name',
      emailPlaceholder: 'Email',
      messageLabel: 'Feedback',
      messagePlaceholder:
        'How do you like our new page design? Anything you specifically like or would like to see improved?',
      submitButtonLabel: 'Share Feedback',
      themeLight: {
        foreground: '#1a1a1a',
        background: '#ffffff',
        border: '1px solid #CCCCCC',
        boxShadow: '0px 3px 6px 0px #00000014',
        borderRadius: '16px',
        formBorderRadius: '16px',
        formContentBorderRadius: '8px',
        inputBackground: '#ffffff',
        inputBorder: '2px solid #CCCCCC',
        inputOutlineFocus: '#1a1a1a',
        submitBackground: '#1a1a1a',
        submitBackgroundHover: '#000000',
        submitBorder: '#1a1a1a',
        submitOutlineFocus: '#1a1a1a',
        cancelBackground: '#ffffff',
        cancelBackgroundHover: '#f4f4f4',
        cancelBorder: '#ffffff',
        cancelOutlineFocus: '#1a1a1a',
      },
    }),
    Sentry.metrics.metricsAggregatorIntegration(),
  ],
  // release: sentryConfig.release,
  replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate,
  replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate,
  sampleRate: sentryConfig.sampleRate,
  tracesSampleRate: sentryConfig.tracesSampleRate,
});
// #endregion

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <StrictMode>
    <ChakraProvider theme={programTheme}>
      <Sentry.ErrorBoundary fallback={(errorData) => <ErrorFallBack {...errorData} />}>
        <Global styles={constants.SJ_FONT_FACE} />
        <BrowserRouter basename='/site'>
          <QueryClientProvider client={queryClient}>
            <AuthDataProvider loader={<Loader />}>
              <EventDataProvider loader={<Loader />}>
                <ThemeProvider>
                  <App />
                </ThemeProvider>
              </EventDataProvider>
            </AuthDataProvider>

            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </ChakraProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Remove TR global styles
document.querySelector('link[href*="../css/UserGlobalStyle.css"]')?.remove();
document.querySelector('link[href*="../css/CustomStyle.css"]')?.remove();
