export type SentryEnvironmentConfig = {
  environment: string;
  enabled: boolean;
  release: string;
  sampleRate: number;
  tracesSampleRate: number;
  replaysSessionSampleRate: number;
  replaysOnErrorSampleRate: number;
};

export const getSentryConfig = ({ hostname = '' }: Location): SentryEnvironmentConfig => {
  const release = import.meta.env.VITE_SENTRY_VERSION || '';
  const config = {
    'secure2.convio.net': {
      environment: 'dev',
      enabled: false,
      release,
      sampleRate: 0,
      tracesSampleRate: 0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    },
    'fundraising.qa.stjude.org': {
      environment: 'qa',
      enabled: true,
      release,
      sampleRate: 0.1,
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    },
    'fundraising.stjude.org': {
      environment: 'prod',
      enabled: true,
      release,
      sampleRate: 1.0,
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    },
  }[hostname];

  const local = {
    environment: 'local',
    enabled: false,
    release,
    sampleRate: 0,
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  };

  return config || local;
};
