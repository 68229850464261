import { Card } from '@frx/frx-program-defaults';
import { Box, Center, Heading } from '@chakra-ui/react';

export type ErrorFallBackProps = {
  error: Error;
  eventId: string;
};
export const ErrorFallBack = ({ error, eventId }: ErrorFallBackProps) => (
  <Center py={6} px={[6, 6, 48]}>
    <Card bg='brand.primary.darkGrey'>
      <Heading color='brand.additional.error' fontSize={24}>
        Something went error - {eventId}
      </Heading>
      <Box color='brand.secondary.swatch12' py={4}>
        {String(error)}
      </Box>
    </Card>
  </Center>
);
