import * as Sentry from '@sentry/react';
import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import { Layout, setVersion } from '@frx/frx-program-defaults';
import { useEffect } from 'react';
import { ROUTES } from './app.constants';
import { FundraisingPages, Help, Login } from './screens';
import packageInfo from '../package.json';
import { useSetSentryUser } from './hooks';

export const App = () => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    setVersion({ version: packageInfo.version });
  }, []);

  // Sentry user configuration
  useSetSentryUser();

  const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterRoutes);

  return (
    <Layout>
      <Routes>
        <Route path='/TR/help' element={<Help />} />
        <Route path='/TR/login' element={<Login />} />
        <Route path={ROUTES.FUNDRAISING_PAGES} element={<FundraisingPages />} />
      </Routes>
    </Layout>
  );
};
