import { useQueryString } from '@frx/frx-react-context';
import { FindParticipant } from '../find-participant';
import { EventLandingPage } from './event-landing/EventLandingPage';
import { PersonalPage } from './personal/PersonalPage';
import { TeamPage } from './team/TeamPage';

const GLOBAL_PERSONAL_PAGE = 'personal';
const GLOBAL_TEAM_PAGE = 'team';
const GLOBAL_SEARCH_PAGE = 'pfind';

export const FundraisingPages = () => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    queryStrings: { pg, px, team_id },
  } = useQueryString();

  if (pg === GLOBAL_SEARCH_PAGE) return <FindParticipant />;
  if (pg === GLOBAL_PERSONAL_PAGE && px) return <PersonalPage />;
  if (pg === GLOBAL_TEAM_PAGE && team_id) return <TeamPage />;

  return <EventLandingPage />;
};
