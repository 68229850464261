import { useAuthDataProvider, useRegistration } from '@frx/frx-react-context';
import { useEffect } from 'react';
import { setUser } from '@sentry/react';

export const useSetSentryUser = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { data: authData } = useAuthDataProvider();
  const { data: regData } = useRegistration();
  const user = authData?.user;

  const username = regData?.screenName || `${user?.name?.first} ${user?.name?.last}`;

  useEffect(() => {
    if (user?.cons_id) {
      setUser({ id: user?.cons_id || 0, email: user?.email?.primary_address || '', username });
    }
  }, [user?.cons_id, user?.email?.primary_address, username]);
};
